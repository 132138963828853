import api from "@/api";

const complaint = {
  list(params) {
    return api.get(`/complaint`, {
      params: params,
    });
  },
  changeState(id, data) {
    return api.patch(`/complaint/${id}/state`, data);
  },
};

export default complaint;
