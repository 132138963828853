<template>
  <div id="d-image">
    <el-dialog
      title="查看图片"
      :visible.sync="visible"
      @closed="handleClosed"
      width="400px"
    >
      <div class="body">
        <el-image
          v-if="imageList.length > 0"
          :src="imageList[0]"
          :preview-src-list="imageList"
        >
        </el-image>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "d-image",
  data() {
    return {
      visible: false,
      imageList: [],
    };
  },
  methods: {
    handleShow(val) {
      this.visible = true;
      this.imageList = val;
    },
    handleCancel() {
      this.visible = false;
    },
    handleClosed() {
      this.src = "";
    },
  },
};
</script>

<style lang="stylus">
#d-image {
  .body {
    text-align: center;
  }
}
</style>
