<template>
  <div id="complaint" v-loading="loading">
    <div class="cm-content">
      <el-table :data="data">
        <el-table-column label="投诉人昵称" prop="user.nickname">
          <template slot-scope="scope">
            {{ scope.row.user.nickname | formatNullValue }}
          </template>
        </el-table-column>
        <el-table-column label="投诉人手机" prop="user.cellphone">
          <template slot-scope="scope">
            {{ scope.row.user.cellphone | formatNullValue }}
          </template>
        </el-table-column>
        <el-table-column label="投诉截图">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.img_url.length > 0"
              type="text"
              @click="handleViewImage(scope.row.img_url)"
              >查看图片</el-button
            >
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="投诉内容">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.content.length > 10"
              placement="top-start"
              width="180"
              trigger="hover"
              :content="scope.row.content"
            >
              <div slot="reference">
                {{ scope.row.content.substr(0, 10) }}...
              </div>
            </el-popover>
            <div v-else-if="scope.row.content.length > 0">
              {{ scope.row.content }}
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="投诉类型">
          <template slot-scope="scope">
            {{ scope.row.type | formatComplaintType }}
          </template>
        </el-table-column>
        <el-table-column label="会话类型">
          <template slot-scope="scope">
            {{ scope.row.conversation_type | formatComplaintConversationType }}
          </template>
        </el-table-column>
        <el-table-column label="投诉类型">
          <template slot-scope="scope">
            {{ scope.row.type | formatComplaintType }}
          </template>
        </el-table-column>
        <el-table-column label="处理状态">
          <template slot-scope="scope">
            {{ scope.row.state | formatComplaintState }}
          </template>
        </el-table-column>
        <el-table-column label="投诉时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              :disabled="scope.row.state === 1"
              @click="handleChangeState(scope.row.pk, { action: 'disable' })"
            >
              封号
            </el-button>
            <el-button
              size="mini"
              type="primary"
              :disabled="scope.row.state === 1"
              @click="handleChangeState(scope.row.pk, { action: 'record' })"
            >
              记录
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row type="flex" justify="center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </el-row>
      <d-image ref="dialog"></d-image>
    </div>
  </div>
</template>

<script>
import DImage from "@/components/dialogs/DImage";

import complaint from "@/api/complaint";

export default {
  name: "complaint",
  data() {
    return {
      loading: false,
      data: [],
      params: {},
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.handleInit(this.$route);
    this.handleList(this.params);
  },
  components: {
    DImage,
  },
  methods: {
    handleInit(route) {
      if (route.query.page) {
        this.params["page"] = route.query.page;
      }
    },
    handleList(params) {
      complaint.list(params).then((res) => {
        this.data = res.results;
        this.total = res.count;
        this.loading = false;
        this.currentPage = parseInt(this.$route.query.page);
      });
    },
    handleViewImage(val) {
      this.$refs.dialog.handleShow(val);
    },
    handleChangeState(pk, data) {
      this.$confirm("是否处理？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          complaint
            .changeState(pk, data)
            .then(() => {
              this.handleList(this.params);
              this.$message({
                type: "success",
                message: "操作成功！",
              });
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.params["page"] = val;
      let params = Object.assign({}, this.params);
      this.$router
        .push({
          name: "complaint",
          query: params,
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (val) {
        if (val.name === "complaint") {
          this.handleInit(val);
          this.handleList(this.params);
        }
      },
    },
  },
};
</script>

<style lang="stylus">
#complaint {
  display: flex;
  height: 100%;
}
</style>
